import React, { MouseEventHandler } from 'react'
import Link from 'next/link'
import styles from './A.module.scss'
import classNames from 'classnames'

interface Props {
  href: string
  onClick?: MouseEventHandler
  className?: string
}

const A: React.FC<Props> = ({ href, children, className, onClick }) => {
  const anchorEl = (
    <a className={classNames(styles.link, className)} onClick={onClick}>
      {children}
    </a>
  )

  return href ? (
    <Link href={href} passHref legacyBehavior>
      {anchorEl}
    </Link>
  ) : (
    anchorEl
  );
}

export default A
