import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import styles from './Button.module.scss'
import Spinner from 'components/common/Spinner/Spinner'

type ButtonSize = 'sm' | 'md' | 'lg' | 'xs'
type ButtonType = 'primary' | 'default' | 'ghost' | 'text' | 'icon' | 'blue'
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize
  mode?: ButtonType
  block?: boolean
  rounded?: boolean
  square?: boolean
  outlined?: boolean
  loading?: boolean
  disabled?: boolean
  danger?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      mode = 'primary',
      block,
      size,
      className,
      square = false,
      danger = false,
      rounded = false,
      outlined = false,
      loading,
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(
          styles.button,
          size,
          className,
          rounded && styles.rounded,
          square && styles.square,
          outlined && styles.outlined,
          block && styles.block,
          disabled && styles.disabled,
          loading && styles.loading,
          danger && styles.danger,
          mode === 'primary' && styles.primary,
          mode === 'default' && styles.default,
          mode === 'ghost' && styles.ghost,
          mode === 'text' && styles.text,
          mode === 'blue' && styles.blue,
          mode === 'icon' && styles.icon
        )}
        {...props}
      >
        {loading && <Spinner />}
        {children}
      </button>
    )
  }
)
