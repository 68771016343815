import React from 'react'
import styles from './CookieBanner.module.scss'
import { Button } from 'components/common/Buttons/Button'
import { RouteEnum } from '../../../enums/RouteEnum'
import { localStorageService } from '../../../services/localStorage.service'
import { LocalStorageKeys } from '../../../enums/local-storage-keys'
import A from 'components/common/A/A'

interface Props {}

export const CookieBanner: React.FC<Props> = ({}) => {
  const [shown, setShown] = React.useState(true)

  const acceptCookies = () => {
    localStorageService.set(LocalStorageKeys.cookiesAccepted, 'true')
    setShown(false)
  }

  React.useEffect(() => {
    const accepted =
      localStorageService.get(LocalStorageKeys.cookiesAccepted) === 'true'

    if (accepted && shown) {
      setShown(false)
    }
  }, [shown])

  if (!shown) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <span className={styles.big}>Cookie Policy</span>
        <span>
          Our website uses cookies to improve your experience on our website.{' '}
          <A href={RouteEnum.PRIVACY_POLICY}>Read More</A>
        </span>
        <span className={styles.line} />
        <span className={styles.decline} onClick={acceptCookies}>
          Decline
        </span>
        <Button size="sm" onClick={acceptCookies}>
          I&apos;m happy with this
        </Button>
      </div>
    </div>
  )
}
