import { LocalStorageKeys } from "../enums/local-storage-keys";

class LocalStorageService {
  private storage: Storage =
    typeof window === 'undefined' ? null : window.localStorage

  get(key: LocalStorageKeys): any {
    return this.storage.getItem(key)
  }

  set(key: LocalStorageKeys, value: string): void {
    this.storage.setItem(key, value)
  }

  remove(key: LocalStorageKeys): void {
    this.storage.removeItem(key)
  }

  clear(): void {
    this.storage.clear()
  }
}

export const localStorageService = new LocalStorageService()
