import classNames from 'classnames'
import React from 'react'
import styles from './Spinner.module.scss'

interface SpinnerProps {
  className?: string
  size?: number | string
}

const Spinner: React.FC<SpinnerProps> = ({ className, size }) => {
  const style = {
    '--spinner-size': typeof size === 'number' ? `${size}px` : size || '1rem',
  } as React.CSSProperties

  return <div style={style} className={classNames(className, styles.spinner)} />
}

export default Spinner
